.Navbar {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  height: 50px;
  position: relative;
  top: 2vh;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background-color: #033a5a;
  border: 1px solid #2e2e4c;
  box-shadow: 3px 9px 16px rgba(152, 149, 149, 0.409),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(206, 196, 196, 0.05);
  -webkit-border-radius: 4rem;
  -moz-border-radius: 4rem;
  -ms-border-radius: 4rem;
  -o-border-radius: 4rem;
}

.list {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  font-size: 6rem;
  list-style: none;
  text-decoration: none;
  justify-content: space-around;
  align-items: center;
}
.listItem {
  text-decoration: none;
  list-style: none;
}
.listItemLinked {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.5rem;
  text-decoration: none;
  border-bottom: none;
  transition: color 0.4s;
  -webkit-transition: color 0.4s;
  -moz-transition: color 0.4s;
  -ms-transition: color 0.4s;
  -o-transition: color 0.4s;
}

.listItemLinked:hover {
  color: var(--dim-green) !important;
  text-decoration: none !important;
  transform: scale(1.2);
  transition: 1s;
}

@media only screen and (max-width: 600px) {
  .Navbar {
    display: none;
  }
}
