.sidebar_navbar {
  width: 100%;
  height: 10vh;
  background-color: #033a5a;
  border: 1px solid #2e2e4c;
  box-shadow: 3px 9px 16px rgba(152, 149, 149, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(206, 196, 196, 0.05);
  font-size: 4rem;
}
.user_photo {
  margin-top: 10px;
}
.sidebar_wrapper {
  width: 300px;
  border-radius: 10px;
  position: relative;
  left: 0%;
  top: 5vh;
  height: 85vh;
  background-color: #033a5a;
  border: 1px solid #2e2e4c;
  box-shadow: 3px 9px 16px rgba(152, 149, 149, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(206, 196, 196, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.8s;
  -webkit-transition: all 0.2;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  z-index: 3000;
  -webkit-transition: all 0.8s;
}

.sidebar_wrapper.active {
  left: -100%;
}

.user_upperpart {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 4px solid #2e80af;
}

.user_photo_logout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.hamburger {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
}

.welcome {
  color: white;
  font-size: 2rem;
}

.sidebar_logout {
  width: 100px;
  height: 30px;
  font-size: 1rem;
  background-color: #2e80af;
  color: black;
  font-weight: 700;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  border: none;
  outline: none;
  margin-bottom: 10px;
}
.sidebar_logout:hover {
  background-color: transparent;
  border: 2px solid #2e80af;
  color: white;
}

.user_lower_part {
  margin-top: 10%;
  height: 90%;
  width: 100%;
}

.user_links {
  color: white;
  font-size: 1.6rem;
}

.common {
  color: #2e80af !important;
  font-size: 3rem;
}

.user_lower_part_list {
  width: 100%;
  height: 68%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  list-style: none;
}

.user_lower_part_list_items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 15%;
  transition: all.4s;
  -webkit-transition: all.4s;
  -moz-transition: all.4s;
  -ms-transition: all.4s;
  -o-transition: all.4s;
}

.room_name {
  width: 20%;
}
.user_lower_part_list_items:hover {
  background-color: #023359;
  color: white;
}
.user_lower_part_list_items_links {
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.user_lower_part_list_items_links:hover {
  text-decoration: none;
}

.user_links {
  width: 73%;
}

@media only screen and (max-width: 600px) {
  .user_lower_part {
    margin-top: 3%;
  }
  .user_lower_part_list {
    height: 50%;
  }
}
