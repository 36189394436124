.main_wrapper {
  width: 100%;
  height: 111rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.input_box input{
  width:90% !important;
}
.signup_text {
  color: #5372fe;
  font-size: 2rem;
  text-align: center;
  margin-top: 10px;
}
.signup_wrapper {
  background-color: #033a5a;
  border: 1px solid #2e2e4c;
  box-shadow: 3px 9px 16px rgba(152, 149, 149, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(206, 196, 196, 0.05);
  width: 40%;
  height: 72%;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 10%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.signup_image {
  width: 100%;
  border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -ms-border-radius: 10px 10px 0 0;
  -o-border-radius: 10px 10px 0 0;
}

.form_box {
  height: 69%;
}
.input_box input{
  height: 13%;
}
.form2 {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
}
.input_box {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.input-field {
  width: 80%;
  padding: 1rem;
  font: 12px/24px "Muli", sans-serif;
  opacity: 0.8;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: #0e4f77;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #2e344d;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5),
    -13px -13px 23px rgba(255, 255, 255, 0.03);
  border-width: 1px 0px 0 1px;
  color: white !important;
}
.input-field::placeholder {
  color: white !important;
}

.input-field:focus {
  background: #0e4f77;
  border: 4px solid #344d2e !important;
  outline: 0;
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5),
    -13px -13px 23px rgba(255, 255, 255, 0.03);
}
label {
  color: #5372fe;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.checkbox_box {
  margin-top: 3rem;
}
.checkbox_1,
.checkbox_2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.signup_submit {
  margin-top: 10px;
  width: 140px;
  height: 40px;
  color: #5372fe;
  font-size: 1.5rem;
  border: 0.1rem solid var(--white);
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.signup_submit:hover {
  background: transparent;
  border: 0.1rem solid black;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}
.marginal {
  text-align: right;
  width: 100%;
  margin-left: 100px;
  margin-top: 10px;
  font-size: 1rem;
  color: white;
}

@media only screen and (max-width: 600px) {
  .main_wrapper {
    height: 88rem;
  }
  .marginal {
    margin-left: 18px;
  }
  .signup_wrapper {
    width: 80%;
    height: 83%;
  }
  label {
    font-size: 1rem;
  }

  .form_box {
    height: 73%;
  }

  .room_button {
    margin: 19px 0 0 0;
  }
  .input_box input{
    height: 12%;
  }
}

@media only screen and (max-width: 380px) {
}
