.recover_detail_wrapper {
  position: absolute;
  height: 60%;
  width: 40%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.559),
    rgba(255, 255, 255, 0.559)
  );
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: inset;

  border: 0.2rem solid var(--white);
}

.toaster {
  width: 100%;
  height: 20%;
}

.recover_detail_form {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 70%;
}
.recover_detail_form button {
  align-self: flex-start;
  margin-left: 69px;
}
.recover_detail_input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 80%;
}

.recover_detail_input label {
  font-size: 2rem;
}
.recover_detail_input input {
  width: 90%;
  height: 40px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: 1.5rem;
  text-align: center;
  border: none;
  outline: none;
  color: #0e4f77;
}

@media only screen and (max-width: 600px) {
  .recover_detail_wrapper {
    width: 90%;
  }
  .recover_detail_form button {
    margin-left: 41px;
  }
}
