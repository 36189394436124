

* {
  margin: 0;
  padding: 0;
  font-size: 62.5%;
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --off-white: #e2e5ec;
  --black: #3a3a3a;
  --gray: #818181;
  --green: #0e4f77;
  --blue: #a0a73e5;
  --dim-green: #fe6a68;
}

.main {
  margin: 0;
  padding: 0;

  width: 100%;
}

body {
  background: url("./veroBG.jpg");
  background-size: cover;
  background-attachment: fixed;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}


