.dashboard_navbar {
  width: 100%;
  height: 10vh;
  background-color: #033a5a;
  border: 1px solid #2e2e4c;
  box-shadow: 3px 9px 16px rgba(152, 149, 149, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(206, 196, 196, 0.05);
  font-size: 4rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
}
.dash_encryption {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.4rem;
}

.waiting_div {
  width: 100%;
  padding: 6px;
  border-radius: 6px;
  background: linear-gradient(225deg, #e6e6e6, #ffffff);
  /* box-shadow: -5px 5px 45px #666666, 5px -5px 45px #ffffff; */
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  
}

.red{
  background-color: rgb(173, 15, 15);
  color: white;
  
}
.green{
  background-color: rgb(14, 120, 14);
  color:white;
}

.red:active{
  box-shadow: -5px 5px 45px #ea0707, 5px -5px 45px #df1010 !important;
}

.green:active{
  box-shadow: -5px 5px 45px rgb(9, 215, 9), 5px -5px 45px rgb(9, 215, 9) !important;
}
.bottom_overlay_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.filebar {
  display: flex;
  width: 19%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
}
.dash_button {
  width: 26%;
  height: 2%;
  margin-left: 10px;
  margin-right: 10px;
  padding: 6px;
  margin-bottom: 10px;
  box-shadow: 3px 9px 16px rgb(0, 0, 0, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(255, 255, 255, 0.05);
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.2);
}
.dash_button:hover {
  box-shadow: -2px -2px 5px #212121, 2px 2px 5px #141414;
}
.logo_secure {
  width: 33%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.dash_encryption {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.4rem;
}

.dash-logo {
  width: 40%;
  height: 100%;
}

.dashboard_room {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.8rem;
}
.dash_meetingroom {
}
.dash_video {
}
.dashboard_network {
  width: 30%;
  height: 100%;
  display: flex;

  align-items: center;
  font-size: 1rem;
}

.dash_meetingroom {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 0px;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* .dash_video {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  width: 53%;
  top: 20%;
  position: fixed;
  left: 40%;
  height: 61%;
} */

#meetingRoomdiv > .videoContainer {
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.videoContainer video {
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

#meetingRoomdiv {
  display: flex !important;
  flex-wrap: wrap;
  max-width: 458px;
  max-height: 562px;
  margin-left: -20px;
  width: 690px;
  border-radius: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MuiPaper-root {
  height: 80%;
  width: 27%;
}
.hostControl_ul {
  background-color: #033a5a !important;
  border: 1px solid #2e2e4c !important;
  box-shadow: 3px 9px 16px rgba(152, 149, 149, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(206, 196, 196, 0.05) !important;
  padding: 2rem 0 2rem 0;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.overlay_wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.backgrounds {
  position: absolute;
  left: 0;
  top: 76px;
  width: 100vw;
  height: calc(100vh - 166px);
  overflow-y: hidden;
  background-size: contain;
  z-index: -5;
}
.louis_background img {
  margin-bottom: 10px;
}
.publicframes-child {
  width: 95%;
  margin: 5px;
}

.reception_wrapper {
  max-width: 198px;
  top: 11vh;
  left: 72vw;
  background-color: white;
  border: 1px solid #2e2e4c;
  text-align: center;
  box-shadow: 3px 9px 16px rgba(152, 0.149, 0.149, 0.4),
    -3px -3px 10px rgba(255, 0.255, 0.255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(206, 0.196, 0.196, 0.05);
  border-radius: 10px;
  font-size: 1rem;
  color: black;
}

/* chatbar */
:focus {
  outline: none;
}
input[type="text"] {
  font: 12px/24px "Muli", sans-serif;
  /* color: #333; */
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}

.effect-2 {
  border: 0;
  padding: 7px 0;
  border-bottom: 1px solid #ccc;
}
.effect-2 ~ .focus-border {
  position: absolute;
  bottom: 0px;
  left: 40%;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.4s;
  margin-bottom: 28px;
}
.effect-2:focus ~ .focus-border {
  width: 77%;
  transition: 0.4s;
  left: 12px;
}

.chatbar_inputs {
  position: relative;
  top: 2vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 13px;
}
.chat_profile {
  width: 13%;
  height: 20%;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background-color: red;
}

.form_chat {
  width: 95%;
  display: flex;
  justify-content: space-between;
}
.nameandbtn{
  margin-left: 10px !important;
  width: 58px !important;
}
#bigtvvideo{
  /* width: 61%; */
  position: fixed;
  /* left: 430px;
  top: 10px; */
  /* padding: 30px; */
  max-height: 500px;
  /* background-color: #0438567b; */
  background-color: rgba(2, 2, 2, 0.68);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 53% !important;
  top: 20%;
  position: fixed;
  left: 40%;
  /* height: 61%; */
}
/* chatbar */
@media only screen and (max-width: 600px) {
  /* .bottomcontrols li i{
    font-size: 23px !important;
  } */
  .bottom_icon{
    font-size: 19px !important;
  }
  .bottom_icon_img{
    width: 24px !important;
  }
  .publicframes-child {
    width: 365%;
    
  }
  .file_inputs{
    top: 71% !important;
  }
  .send{
    top: 74% !important;
  }
  .hostControl_ul {
    height: 56% !important;
    position: absolute;
    left: 90vw;
    top: 19vh;
    right: 0px;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .wallpaper_left{
    margin-left: 9px !important;
  }
  .reception_wrapper {
    width: 39%;
    left: 57vw !important;
  }
  .overlay_wrapper {
    z-index: 9999999;
    top: 30vh;
    left: 58vw;
    background-color: #033a5a;
    max-width: 28%;
    max-height: 63%;
  }
  .waiting_list_button_bottom{
    font-size: 21px !important;
    margin-right: -24px;
  }
}

@media only screen and (min-width: 600px) {
  .divforemoji {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 35%;
    width: 5%;
  }

  .hostControl_ul {
    position: absolute;
    left: 94vw;
    right: -4%;
    width: 40px;
    top: 20vh;
    height: 60%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
}
@media only screen and (min-width: 600px) {
  .dashboard_navbar {
    justify-content: space-between;
  }
  .dashboard_network {
    width: 14%;
  }

  /* .dash_video {
    display: block !important;
  } */
}

.nameForStream {
  color: white !important;
}
.overlay_reset,
.overlay_close {
  padding: 7px;
  width: 73%;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
  font-size: 10px;
  cursor: pointer;
  border: none;
}
.overlay_reset {
  margin-bottom: 12px;
  background-color: #4fadd3;
  color: white;
}
.overlay_close {
  margin-top: 15px;
  background-color: red;
  color: white;
}
.fullbtnmet {
  cursor: pointer;
  /*background: rgba(3, 58, 90, 0.85) !important;
  *//*box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  */backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  /*border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  */font-size: 6px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  border-radius: 50;
  width:24px;
  height: 24px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.fullbtnmet:hover {
  box-shadow: 7px 7px 8px #043453, 7px 7px 8px #044065;
}

.fullbtnmet img{
  width: 24px;
}
.nameandbtn {
  border-radius: 6px;
  background: #033a5a;
  box-shadow: 16px 16px 89px #011724, -16px -16px 89px #055d90;
  margin-top: 3px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  position: relative;
  margin-left: 1px !important;
}

.waiting_name {
  color: black !important;
  font-size: 17px;
  margin-top: 2px;
}
.recording_overlay_text {
  display: flex;
  justify-content: space-between;
}
.recording_overlay {
  z-index: 100000000000;

  padding: 10px;
  color: grey;
  font-size: 2.4rem;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  max-height: 550px;
  overflow: auto;
}

.louis_private_frames {
  position: absolute;
  left: 80vw;
  top: 11vh;
  max-height: 509px;
  overflow-y: auto;
  width: 92%;
  left: 2vw;
}

.louis_private_frames img {
  width: 96% !important;
}
@media only screen and (min-width: 600px) {
  .dashboard_room {
    
    font-size: 1.5rem;
  }

  .welcome_vicky {
    font-size: 1.5rem !important;
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 600px) {
  .dash_encryption {
    display: none;
  }
  .welcome_vicky {
    margin-top: 0px;
  }
  .chatbar_inputs{
    top:-2vh;
  }
  .filebar {
    width: 60%;
    height: 6%;
  }
  #meetingRoomdiv {
    max-width: 0px;
    width: 0px;
  }
  .MuiPaper-root {
    height: 80%;
    width: 87%;
  }
  .divforemoji {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 35%;
    width: 15%;
  }
  .dash_encryption {
    display: none;
  }
  .dashboard_room {
    margin-top: 4%;
    width: 31%;
  }
  .logo_secure {
    width: 14%;
  }
  .logo_secure img {
    width: 124%;
    height: 40%;
  }
  .videoContainer {
    
    width: 350px;
  }
.videoButton{
  display: flex;
  flex-direction: row;
}
  .waiting_name {
    font-size: 14px !important;
  }
  .chat_image_preview{
    position: relative;
    top: -5%;
  }
}
