.dialog {
  width: 100vw;
  height: 100vh;
  z-index: 5000 !important;
}

.contact_card {
  border-radius: 12px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 5px 5px 19px #696969, -5px -5px 19px #ffffff;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.dialog_content {
  width: 100%;
  height: 50vh;
}
.dialog_content_text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.email_contact {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px !important;
  outline: none !important;
  border: none !important;
}

.contact_button:active {
  outline: none !important;
  border: none !important;
}
