.forget_nav {
  width: 100%;

  height: 50px;
  position: relative;

  background-color: #033a5a;
  border: 1px solid #2e2e4c;
  box-shadow: 3px 9px 16px rgba(152, 149, 149, 0.409),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(206, 196, 196, 0.05);
  -webkit-border-radius: 4rem;
  -moz-border-radius: 4rem;
  -ms-border-radius: 4rem;
  -o-border-radius: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forget_logo_vero {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100%;
  padding: 1rem;
}

.forget_version {
  width: 70%;
  font-size: 2rem;
}
.recover_wrapper {
  position: absolute;
  height: 40%;
  width: 30%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.559),
    rgba(255, 255, 255, 0.559)
  );
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: inset;

  border: 0.2rem solid var(--white);
}

.registered_email {
  width: 100%;
}
.input_recover,
#standard-basic {
  width: 100% !important;
}
.registered_email_para {
  color: black;
  font-size: 2rem;
  text-align: center;
}

.recover_content {
  width: 80%;
  height: 80%;
  margin: 0 10% 0 10%;
}

.recover_buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.recover_form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media only screen and (max-width: 600px) {
  .recover_wrapper {
    width: 90%;
  }
}
