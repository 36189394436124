

* {
  font-family: "Fira Sans", sans-serif;
  font: 1.6rem Helvetica;
}

.box_room_id {
  width: 30%;
  height: 100%;
  font-size: 1rem;
  color: white;
}
.date{
  background-color: blue;
}
.date {
  background: #0e4f77;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #2e344d;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5),
    -13px -13px 23px rgba(255, 255, 255, 0.03);
  border-width: 1px 0px 0 1px;
  padding: 1rem 1rem 1rem 1rem;
  font-size: 1.5rem;
  width: 100%;
}
.date::placeholder {
  color: white;
}
.date:focus {
  border: 1px solid #344d2e !important;
  color: #495057;
  outline: 0;
  background: rgb(17, 20, 31);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5),
    -13px -13px 23px rgba(255, 255, 255, 0.03);
}
.box_date {
  width: 30%;
  height: 100%;
  font-size: 1rem;
  color: white;
}

.box_time {
  width: 30%;
  height: 100%;

  font-size: 1rem;
  color: white;
}
.box_common {
  display: flex;
  justify-content: center;
  align-items: center;
}
.common2 {
  font-size: 1.5rem !important;
  background-color: #033a5a !important;
  color: #2e80af;
}
.blue_box {
  background-color: #033a5a;
  width: 100%;
  height: 15%;
  margin-top: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 3px 9px 16px rgba(152, 149, 149, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(206, 196, 196, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_wrapper {
  width: 50%;
  height: 70vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  z-index: 1000;
}
.border2:input[type="text"]{
color: white !important;
}
.common_content {
  font-size: 1.6rem;
  color: white;
}
.chathive_button{
             position: fixed;
             border-radius: 63px;
             background: #033958;
             box-shadow:  5px 5px 10px #022437,             -5px -5px 10px #044e79;
             outline: none;
             border: none;
             top: 95vh;
             z-index: 10000;
             right: 5%;
             width: 9%;
             height: 5%;
             display: flex;
             justify-content: center;
             align-items: center;
             border-radius: 10px;
             -webkit-border-radius: 10px;
             -moz-border-radius: 10px;
             -ms-border-radius: 10px;
             -o-border-radius: 10px;
             cursor: pointer;
}

/* .chathive{
  width: 112%;
  height: 57px;

} */
.chathive{
  width: 112%;
  height: 57px;

}
.name {
  text-align: center;
}
.dashboard_user_info {
  width: 100%;
  height: 35%;
  padding: 3rem 0 0 2rem;
  color: white;
  background-color: #033a5a;
  border: 1px solid #2e2e4c;
  box-shadow: 3px 9px 16px rgba(152, 149, 149, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(206, 196, 196, 0.05);
  border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -ms-border-radius: 10px 10px 0px 0px;
  -o-border-radius: 10px 10px 0px 0px;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  font-family: "Fira Sans", sans-serif;
  font: 1.6rem Helvetica;
}
#border {
  border-bottom: 2px solid white !important;
}
.dashboard_info {
  width: 62%;
}
.dashboard_video {
  height: 101%;
  width: 26%;
  margin: 0rem 2rem 0rem 0rem;
}
.dashboard_user_social_media_info {
  height: 25%;
  width: 100%;
  padding: 1rem 0rem 0rem 2rem;
  background-color: #033a5a;
  border: 1px solid #2e2e4c;
  box-shadow: 3px 9px 16px rgba(152, 149, 149, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(206, 196, 196, 0.05);
  border-radius: 0px 0px 10px 10px;
  -webkit-border-radius: 0px 0px 10px 10px;
  -moz-border-radius: 0px 0px 10px 10px;
  -ms-border-radius: 0px 0px 10px 10px;
  -o-border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.option {
  display: flex;
  width: 100%;
  height: 40%;
  align-items: center;
  margin-right: 20px;
}
.option label {
  margin-right: 20px;
}
.option_value {
  font-size: 2rem;
}

.room_id {
  width: 100%;
  background-color: transparent;
}

.meeting_mobile_enter{
  width: 28%;
  font-size: 7px !important;
}
.meeting_mobile_add{
  font-size: 7px !important;
  
}
.room_id {
    height: 35%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    padding: 1rem 1rem 2rem 2rem;
    /* background-color: #033a5a;
    border: 1px solid #2e2e4c;
    box-shadow: 3px 9px 16px rgba(152, 149, 149, 0.4),    -3px -3px 10px rgba(255, 255, 255, 0.06),    inset 14px 14px 26px rgb(0, 0, 0, 0.3),    inset -3px -3px 15px rgba(206, 196, 196, 0.05);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px; */
}
.room_content {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.room_button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.room_name {
  width: 20%;
  font-size: 1.6rem;
  color: white;
}
.dashboard_button {
  margin-top: 10px;
}
.metting_dialog {
  width: 100vw;
  height: 100vh;
  z-index: 5000 !important;
}

.metting_dialog_content {
  width: 90%;
  margin: 0 5% 0 5%;
  height: 50vh;
  margin-top: 30px;
}
.metting_dialog_content_text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}
.email_metting_button {
  margin-top: 10px !important;
}
.update_profile_form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.profile_pic_dash {
  width: 101px;
  height: 101px;
}
.image_info {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.dash_camera {
  width: 125%;
  position: relative;
  right: 37%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.read_only {
  display: flex;
  justify-content: space-between;
  font-size: 16rem !important;
}

.contact_card {
  border-radius: 12px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 5px 5px 19px #696969, -5px -5px 19px #ffffff;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.update_form_left_part {
  height: 16%;
}
.update_form_right_part {
  width: 100%;
  height: 70%;
  margin-top: 80px;

  display: flex;
  flex-direction: column;
}

.material_ui_box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
}

.write_only {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
}
.two_inputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 20%;
}

.small_links {
  width: 50%;
  margin: 10px 10px 10px 10px;
}
.website_links {
  font-size: 2rem;
}
.dash_encryption2{
  margin-top: -100px;
  margin-left: -24px;
  font-size: 1.4rem !important;
  color: white;
  
}

.encryption2_img{
  width: 42%;
}

@media only screen and (min-width:600px){
  .room_name{
    width: 28%;
  }
}
@media only screen and (max-width: 600px) {
  .room_input{
    margin-top: 24px;
    height: 15%;
    width: 86% !important;
  }
  .room_input::placeholder{
    font-size: 1.2rem !important;
  }

  
  .chathive_button{
    top: 89vh;
    width: 14%;
  }
  .encryption2_img{
    width: 57%;
  }
  .dash_encryption2{
    margin-top: -22%;
    margin-left: -37px;
    font-size: 1.4rem !important;
    color: white;
  }
  .home_back {
    height: 76rem;
  }
  .user_wrapper {
    padding: 1rem;
    width: 100%;
    top: 51%;
    height: 55rem;
  }
  .dash_camera {
    max-height: 100%;
    max-width: 125%;
  }
  .room_name,
  .room_input,
  .common_content {
    font-size: 1rem;
    
  }

  .room_name{
    margin-top: 21px;
    width: 52%;
  }
  .room_input::placeholder {
    font-size: 0.8rem;
  }
  .dashboard_video {
    width: 32%;
    height: 101%;
  }
  .dash_camera{
    bottom: 10%;
    left: -14%;
  }
}
