

.reception_main {
  position: absolute;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.reception_nav {
  width: 100%;
  height: 10%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 0 10px 10px 0;
  /* background-color: #1f4c6e; */
  background: #0e4f77;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #2e344d;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  
  box-shadow: 0px 2px 26px rgb(0, 0, 0, 0.5),
    0px 7px 13px rgba(255, 255, 255, 0.03);
  color: white;
  position: fixed;
  top: 0%;
}
.logo {
  width: 20%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  color: white;
}
.logo_img {
  width: 100px;
  height: 80px;
}

.reception_content {
  width: 90%;
  margin: 0 5% 0 5%;
  height: 80%;

  position: absolute;
  top: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.host_details {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.room {
  font-size: 2rem;
  color: white;
}
.host_details_part1,
.host_details_part2 {
  width: 100%;
  height: 20%;
}
.host_details_part1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.host_details_part2 {
  color: white;
  font-size: 2rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.waiting_for_host {
  font-size: 1rem !important;
  font-weight: 700;
  outline: none !important;
}

.streaming_part {
  width: 100%;
  height: 60%;

  display: flex;
  justify-content: space-between;
}

.news_streaming {
  width: 45%;
  height: 100%;
  /* background-color: #1f4c6e; */
  background: #0e4f77;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #2e344d;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  /* box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03), inset 14px 14px 70px rgb(0,0,0,0.2), inset -15px -15px 30px rgba(255,255,255, 0.04); */
  box-shadow: 0px 2px 26px rgb(0, 0, 0, 0.5),
    0px 7px 13px rgba(255, 255, 255, 0.03);
  color: white;
}

.video_streaming {
  width: 45%;
  height: 100%;
  /* background-color: #1f4c6e; */
}

.quotes {
  width: 100%;

  height: 8vh;
  /* background-color: #1f4c6e; */
  background: #0e4f77;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #2e344d;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;

  box-shadow: 0px 2px 26px rgb(0, 0, 0, 0.5),
    0px 7px 13px rgba(255, 255, 255, 0.03);
  color: white;
  padding: 1rem;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.crypto_quotes,
.stock_quotes {
  width: 100%;
  height: 50%;

  display: flex;

  align-items: center;
}

.crypto_para,
.stock_para {
  font-size: 2rem;
  color: white;
}
.meeting_main_buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.footer_buttons {
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.newsImage {
  width: 90%;
}

.news_streaming {
  /* border: 4px solid white; */
}

.news_image_part {
  width: 100%;
  height: 90%;
  text-align: center;
}

.different_news_buttons {
  margin: 0 5% 0 5%;
  width: 90%;
  height: 10%;
  display: flex;
}

.news_buttons {
  width: 100%;
  height: 100%;
  background: transparent;
}

.news {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none !important;
  border: none !important;
  margin-right: 10px;
}

.video_part,
.video_streaming_image {
  width: 100%;
  height: 100%;
}
#waitingforhost{
  /* width: 17% !important; */
  font-size: 2rem !important;
}
.waiting_for_host{
  width: 17% !important;
}
.video_part {
  
  background: #0e4f77;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #2e344d;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  
  box-shadow: 0px 2px 26px rgb(0, 0, 0, 0.5),
    0px 7px 13px rgba(255, 255, 255, 0.03);
  color: white;
}
.encryption {
  display: flex;
  justify-content: center;
  align-items: center;
}

#waitingforhost,#hosthasarrived{
  font-size: 2rem;

}
@media only screen and (min-width: 600px) {
  .logo {
    width: 36%;
  }

  .encryption {
    width: 52%;
  }
  .logo_img {
    width: 33%;
    height: 56%;
  }
}
@media only screen and (max-width: 600px) {
  #waitingforhost,#hosthasarrived{
    font-size: 1rem !important;
    
  }
  .waiting_for_host{
    margin-top: 25px !important;
    width: 52% !important;
  }
  .news_image_part iframe{
    height: 100%;
  }
  .room{
    margin-top: 16px;
  }
  .host_details {
    height: 21%;
  }
  .logo_img {
    width: 33%;
    height: 55%;
  }
  .host_details_part1 {
    height: 41%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .host_details_part2 {
    height: 40%;
    width: 97% !important;
  }
  .streaming_part {
    flex-direction: column;
    justify-content: space-between;
  }
  .news_streaming,
  .video_streaming {
    width: 100%;
    height: 45%;
  }

  .footer_buttons {
    width: 100%;
  }

  .streaming_part {
    height: 50%;
  }
  .streaming_button {
    font-size: 1rem !important;
  }

  .logo {
    width: 90%;
  }
}
