.public-profile-pic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: -5px -5px 10px #808080, 5px 5px 10px #ffffff;
  padding: 1rem;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-top: 10px;
}
.profile_name_list {
  width: 60%;
  margin-top: 21px;
}
.profile_organisation_list {
  font-size: 2.4rem;
}
.biolinks_bio {

  border-radius: 10px;
  background: #ffffff;
  box-shadow: -5px -5px 10px #808080, 5px 5px 10px #ffffff;
  padding: 1rem;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-top: 10px;
}

.biolinks_links {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: -5px -5px 10px #808080, 5px 5px 10px #ffffff;
  padding: 1rem;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-top: 10px;
  height: 60%;
}

.biolinks_links ul li a {
  color: blue;
}
.biolinks_links ul li {
  font-size: 3rem;
  cursor: pointer;
}

.public_profile_dialog {
  width: 200% !important;
  position: absolute !important;
  transform: translate(-26%, 0%);
  -webkit-transform: translate(-26%, 0%);
  -moz-transform: translate(-26%, 0%);
  -ms-transform: translate(-26%, 0%);
  -o-transform: translate(-26%, 0%);
  height: 106% !important;
}

@media screen and (max-width: 600px) {
  .public_profile_dialog {
    width: 105% !important;
    position: absolute;
    transform: translate(-3%, 0);
    -webkit-transform: translate(-3%, 0);
    -moz-transform: translate(-3%, 0);
    -ms-transform: translate(-3%, 0);
    -o-transform: translate(-3%, 0);
  }
}
